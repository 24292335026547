import {Actions, StateChanges} from './stateChanges'
import axios from 'axios'
import {AppConfig} from './../appConfig'



axios.defaults.headers = {
  'Content-Type': process.env.VUE_APP_TOOL_NAME === 'remove-nikud'
                    ? 'application/json' // new for Maleify v2.0 (i.e. for RUN_REMOVE_NIKUD)
                    : 'text/plain;charset=UTF-8'
}
const axiosInstance = axios.create({timeout: 300000})
var source

export default {
  [Actions.RUN_TRANSLITERATE]: function ({ commit, state }) {   
    let textForApi = state.textForApiCall
    if (textForApi !== '' ) {
      commit(StateChanges.TRANSLITERATE_RUNNING)                 
      if(source){
        source.cancel();
     }
     const CancelToken = axios.CancelToken;
     source = CancelToken.source();
     axiosInstance.post(AppConfig.SERVER, JSON.stringify({
      data: textForApi          
      }), { cancelToken: source.token})
      .then(function (response) {
        commit(StateChanges.SET_TRANSLITERATE_RESULTS, response.data.res)
        commit(StateChanges.TRANSLITERATE_COMPLETE)
      })
      .catch(function (thrown) {
        if (axios.isCancel(thrown)) {
         // console.log('Request cancelled')
        }
        else {      
          commit(StateChanges.TRANSLITERATE_FAILED)
          console.log('Error')
        }
     
      })
     } else {
        //window.history.replaceState( {} , '', location.href.split("?")[0] )
     }
    },
    [Actions.RUN_REMOVE_NIKUD]: function ({ commit, state }) {   
      let textForApi = state.textForApiCall
      if (textForApi !== '' ) {
        commit(StateChanges.TRANSLITERATE_RUNNING)                 
        if(source){
          source.cancel();
       }
       const CancelToken = axios.CancelToken;
       source = CancelToken.source();
       axiosInstance.post(AppConfig.REMOVE_SERVER, JSON.stringify({
        data: textForApi,
        genre: 'rabbinic',
        fQQ: true          
       }), { cancelToken: source.token})
        .then(function (response) {
          commit(StateChanges.SET_REMOVE_NIKUD_RESULTS, response.data.results)
          commit(StateChanges.TRANSLITERATE_COMPLETE)
        })
      .catch(function (thrown) {
        if (axios.isCancel(thrown)) {
          // console.log('Request cancelled')
        }
        else {      
            commit(StateChanges.TRANSLITERATE_FAILED)
            console.log('Error')
          }
        
        })
      } else {
        commit(StateChanges.SET_REMOVE_NIKUD_RESULTS, '')
      }
    }
  }
